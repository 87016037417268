
import { defineComponent } from 'vue'
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";

export default defineComponent({
  components: {
    InputText,
    InputNumber
  },
  props: {
    rowLabel: String,
    posObject: Object,
  },
  data() {
    return {
      data: null as any,
    };
  },
  mounted() {
    if(this.posObject) {
      this.data = this.posObject;
    }
  },
  watch: {
    posObject: {
      handler: function (val: any, oldVal: any) {
        this.data = val;
        this.$emit("updatePosObject", val);
      },
      deep: true,
    },
  }
})
